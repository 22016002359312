import { Operation, EntityType } from "../action/CurrentActions";
import { sortListBy } from "../../util/Utils";

export const ListName = {
  accountUsers: "accountUsers",
  accountUserFollowers: "accountUserFollowers",
  accounts: "accounts",
  accountChannels: "accountChannels",
  channels: "channels",
  channelSubscribers: "channelSubscribers",
  channelSubscriptions: "channelSubscriptions",
  channelContent: "channelContent",
  channelPlaylists: "channelPlaylists",
  channelPosts: "channelPosts",
  channelPostComments: "channelPostComments",
  chapters: "chapters",
  chatConversations: "chatConversations",
  chatMessages: "chatMessages",
  chatMessageContent: "chatMessageContent",
  graphSearchResults: "graphSearchResults",
  ownedChannels: "ownedChannels",
  playlistContent: "playlistContent",
  profilePlaylists: "profilePlaylists",
  profilePosts: "profilePosts",
  recommended: "recommended",
  recentlyUploaded: "recentlyUploaded",
  unreadNotificationMessages: "unreadNotificationMessages",
  userChannels: "userChannels",
  userProfilePostComments: "userProfilePostComments",
  userUploads: "userUploads",
};

export const entityListKeyMapping = {
  //entityTypeName:       {listName: parentKeyName},
  // Set parentKeyName as undefined for lists that should NOT listen to CREATE
  // operations. See the logic in updateCurrentListItemDispatcher
  accountInvite: { openAccountInvites: "accountId" },
  accountUser: { accountUsers: "accountId" },
  accountUserFollower: { accountUserFollowers: "userProfileId" },
  channel: { channels: "accountId" },
  channel: {
    ownedChannels: "ownerAccountUserId",
    userChannels: "userProfileId",
    channels: "accountId",
  },
  channelPost: {
    channelPosts: "channelId",
  },
  channelShare: { channelShares: "channelId" },
  channelSubscription: {
    channelSubscriptions: "accountId",
    channelSubscribers: "channelId",
  },
  chatMessage: {
    chatMessages: "chatConversationId",
  },
  chatConversation: {
    chatConversations: "accountId",
  },
  comment: {
    channelPostComments: "channelPostId",
    comment: "contentId",
    userProfilePostComments: "userProfilePostId",
  },
  content: {
    channelContent: "channelId",
    chatMessageContent: undefined,
    myUploads: "ownerAccountUserId",
    popular: undefined,
    recentlyUploaded: "ownerAccountUserId",
    recommended: undefined,
    searchResults: undefined,
    userUploads: "userProfileId",
    video: undefined,
  },
  contentShare: { contentShares: "contentId" },
  entitySocketSession: { onlineEntitySocketSessions: "accountId" },
  notificationMessage: { unreadNotificationMessages: "accountId" },
  playlist: {
    channelPlaylists: "channelId",
    profilePlaylists: "userProfileId",
  },
  playlistContent: { playlistContent: "playlistId" },
  playlistShare: { playlistShares: "playlistId" },
  userProfilePost: {
    profilePosts: "userProfileId",
  },
  upload: { recentUploads: "accountId" },
};

const sortFieldNameMapping = {
  //listName:       [{fieldName: reverse}],
  openAccountInvites: [{ emailAddress: false }],
  accountUsers: [{ fullName: false }],
  channels: [{ updatedDate: true }],
  channelContent: [{ uploadedDate: true }],
  channelPosts: [{ createdDate: true }],
  channelSubscriptions: [{ name: false }],
  chapters: [{ startTime: false }],
  chatMessages: [{ createdDate: true }],
  fileUpload: [{ id: true }],
  chatMessages: [{ createdDate: false }],
  channelShares: [{ createdDate: false }],
  channelPlaylists: [{ name: false }],
  comment: [{ createdDate: false }],
  contentShares: [{ createdDate: false }],
  myUploads: [{ uploadedDate: true }],
  ownedChannels: [{ name: false }],
  playlistContent: [{ ordering: false }],
  popular: [{ updatedDate: true }],
  profilePosts: [{ createdDate: true }],
  profilePlaylists: [{ name: false }],
  recentlyUploaded: [{ uploadedDate: true }],
  recommended: [{ updatedDate: true }],
  ringCentralRecordings: [{ recordingDate: true }],
  teamsRecordings: [{ recordingDate: true }],
  unreadNotificationMessages: [{ createdDate: true }],
  userChannels: [{ name: false }],
  userUploads: [{ uploadedDate: true }],
  webexRecordings: [{ recordingDate: true }],
  zoomRecordings: [{ recordingDate: true }],
};

const currentListActionReducer = (state, action) => {
  let newState;
  switch (action.type) {
    case "CURRENT_LIST_ACTION":
      newState = Object.assign({}, state);
      newState.list[action.listName] = Array.isArray(action.currentList)
        ? sortList(action.currentList, action.listName)
        : action.currentList;
      break;
    case "MERGE_CURRENT_LIST_ACTION":
      newState = Object.assign({}, state);
      if (typeof entityListKeyMapping[action.entityTypeName] !== "undefined") {
        const entityLists = entityListKeyMapping[action.entityTypeName];
        for (var listName in entityLists) {
          if (entityLists.hasOwnProperty(listName)) {
            if (typeof newState.list[listName] !== "undefined") {
              newState.list[listName] = mergeList(
                newState.list[listName],
                action.updatedItemsList
              );
            } else {
              newState.list[listName] = action.updatedItemsList;
            }
          }
        }
      }
      break;
    case "UPDATE_CURRENT_LIST_ITEM_ACTION":
      newState = Object.assign({}, state);
      let updatedList = updateListItem(
        state.list[action.listName],
        action.item,
        action.operation
      );
      if (action.listName === ListName.unreadNotificationMessages)
        updatedList = updatedList.filter(
          (notificationMessage) => !notificationMessage.isRead
        );
      newState.list[action.listName] = sortList(updatedList, action.listName);
      break;
    // Remove any items in the lists which parent id's have changed:
    case "REMOVE_CURRENT_LIST_ITEM_PARENT_CHANGE_ACTION":
      newState = Object.assign({}, state);
      // Iterate over the lists for the entity:
      const keyMapping = entityListKeyMapping[action.entityTypeName];
      if (keyMapping) {
        // And find the items in the lists:
        for (const [listName, parentKeyName] of Object.entries(keyMapping)) {
          const item = findItemInList(state.list[listName], action.item);
          // To check if their parent key's have changed:
          if (item && item[parentKeyName] !== action.item[parentKeyName]) {
            // And remove if the parent key has changed:
            newState.list[listName] = removeItemInList(
              state.list[listName],
              action.item
            );
          }
        }
      }
      break;
    default:
  }
  return newState || state;
};

export const updateListItem = (sourceList = [], item, operation) => {
  let updatedList = sourceList.slice();
  const i = indexOf(sourceList, item);
  switch (operation) {
    case Operation.Create:
      if (i >= 0) updatedList[i] = item;
      else updatedList.push(item);
      break;
    case Operation.Update:
      if (i >= 0) updatedList[i] = Object.assign(sourceList[i], item);
      break;
    case Operation.Delete:
      if (i >= 0) updatedList.splice(i, 1);
      break;
    default:
  }
  return updatedList;
};

export const removeItemInList = (sourceList = [], item) => {
  let updatedList = sourceList.slice();
  while (indexOf(updatedList, item) >= 0) {
    updatedList.splice(indexOf(updatedList, item), 1);
  }
  return updatedList;
};

export const sortList = (list, listName) => {
  if (!list) return [];
  let updatedList = list.slice();
  const sortFields = sortFieldNameMapping[listName];
  if (sortFields) {
    for (var i = sortFields.length - 1; i >= 0; i--) {
      var sortField = sortFields[i];
      for (var fieldName in sortField) {
        if (sortField.hasOwnProperty(fieldName)) {
          updatedList = sortListBy(list, fieldName, sortField[fieldName]);
        }
      }
    }
  }
  return updatedList;
};

export const sortCommentList = (list, sortFieldName, reverse) => {
  if (typeof list !== "undefined") {
    let sortedList = list.slice();
    sortedList.sort(function (a, b) {
      let sortFieldA, sortFieldB;
      switch (sortFieldName) {
        case "createdByUser":
          sortFieldA = a[sortFieldName].fullName;
          sortFieldB = b[sortFieldName].fullName;
          break;
        case "createdDate":
        case "playtime":
        default:
          sortFieldA = a[sortFieldName];
          sortFieldB = b[sortFieldName];
          break;
      }
      if (sortFieldA === sortFieldB) {
        sortFieldA = a.id;
        sortFieldB = b.id;
      }
      return reverse
        ? sortFieldB > sortFieldA
          ? 1
          : -1
        : sortFieldA > sortFieldB
        ? 1
        : -1;
    });
    return sortedList;
  } else {
    return [];
  }
};

export const sortChannelContent = (contentList, channel) => {
  let sortField = "uploadedDate";
  let reverse = true;
  switch (channel?.contentSortOrder) {
    case "NameAsc":
      reverse = false;
    case "NameDesc":
      sortField = "name";
      break;
    case "DateAsc":
      reverse = false;
      break;
    case "DateAsc":
      reverse = true;
      break;
  }
  return contentList && channel
    ? sortlListByFieldName(contentList, sortField, reverse)
    : contentList;
};

export const sortlListByFieldName = (list, sortFieldName, reverse) => {
  if (typeof list !== "undefined") {
    const getSortFieldValue = (item) => {
      const sortFieldList = sortFieldName.split(".");
      let value = item;
      for (let i = 0; i < sortFieldList.length; i++)
        value = value ? value[sortFieldList[i]] : 0;
      return value;
    };
    let sortedList = list.slice();
    sortedList.sort(function (a, b) {
      const sortFieldA = getSortFieldValue(a);
      const sortFieldB = getSortFieldValue(b);
      return reverse
        ? sortFieldB > sortFieldA
          ? 1
          : -1
        : sortFieldA > sortFieldB
        ? 1
        : -1;
    });
    return sortedList;
  } else {
    return [];
  }
};

export const mergeList = (sourceList, updatedItemList) => {
  let mergedList = sourceList.slice();
  for (
    var updatedItemIndex = 0;
    updatedItemIndex < updatedItemList.length;
    updatedItemIndex++
  ) {
    let updatedItem = updatedItemList[updatedItemIndex];
    let sourceIndex = indexOf(sourceList, updatedItem);
    if (sourceIndex >= 0)
      mergedList[sourceIndex] = Object.assign(
        sourceList[sourceIndex],
        updatedItem
      );
    else mergedList.push(updatedItem);
  }
  return mergedList;
};

export const findItemInList = (list, item) => {
  if (!item || !list) return null;
  for (var i = 0; i < list.length; i++) {
    let testItem = list[i];
    if (
      testItem.id === item.id ||
      (typeof testItem.tempId !== "undefined" &&
        typeof item.tempId !== "undefined" &&
        testItem.tempId === item.tempId)
    )
      return testItem;
  }
  return null;
};

export const findItemInListWithId = (list, idFieldName, id) => {
  for (let i = 0; i < list.length; i++) {
    const testItem = list[i];
    if (testItem[idFieldName] === id) return testItem;
  }
  return null;
};

export const findItemsInListWithId = (list, idFieldName, id) => {
  let items = [];
  for (var i = 0; i < list.length; i++) {
    const testItem = list[i];
    if (testItem[idFieldName] === id) items.push(testItem);
  }
  return items;
};

export const findItemsInListWithIds = (
  list = [],
  idFieldName,
  idArray = []
) => {
  return list.filter((item) => idArray.indexOf(item[idFieldName]) >= 0);
};

export const findItemInListWithEntityTypeAndId = (list, entityType, id) => {
  for (var i = 0; i < list.length; i++) {
    var testItem = list[i];
    if (testItem["id"] === id && testItem["entityType"] === entityType) {
      return testItem;
    }
  }
  return false;
};

export const findItemInListWithEntityTypeAndParentId = (
  list,
  entityType,
  parentId
) => {
  for (var i = 0; i < list.length; i++) {
    var testItem = list[i];
    if (
      testItem["parentId"] === parentId &&
      testItem["entityType"] === entityType
    ) {
      return testItem;
    }
  }
  return false;
};

export const indexOf = (list, item) => {
  for (var i = 0; i < list.length; i++) {
    let testItem = list[i];
    if (
      testItem.id === item.id ||
      (typeof testItem.tempId !== "undefined" &&
        typeof item.tempId !== "undefined" &&
        testItem.tempId === item.tempId)
    )
      return i;
  }
  return -1;
};

export default currentListActionReducer;
