import request from "superagent";
import {
  processServiceResponse,
  getAuthHeaders,
  getIdentityHeader,
  getBaseUrl,
  logDebug,
} from "./ServiceUtil";
import { randomString, removeEmptyElements } from "../util/Utils";
import { EntityType } from "../redux/action/CurrentActions";

export default class CreateEntityService {
  createEntity(
    dispatch,
    getState,
    entityTypeName,
    queryObject = {},
    formObject = {}
  ) {
    queryObject = queryObject || {};
    queryObject.tempId = randomString(5);
    return new Promise(async (resolve, reject) => {
      let url = `${getBaseUrl(getState)}/api/v1/${entityTypeName}`;
      const authHeaders = await getAuthHeaders(getState);
      const idHeader =
        entityTypeName === EntityType.UserSession
          ? await getIdentityHeader()
          : {};
      logDebug("createEntity", { url });
      request
        .post(url)
        .query(queryObject)
        .set(authHeaders)
        .set(idHeader)
        .set("Accept", "application/json")
        .type("application/x-www-form-urlencoded")
        .send(removeEmptyElements(formObject))
        .end((error, response) => {
          processServiceResponse(
            dispatch,
            getState,
            error,
            response,
            resolve,
            reject
          );
        });
    });
  }
}

export var createEntityService = new CreateEntityService();
